<template>
  <div
    id="contact"
    class="bg-black border-t-4 border-bronze-center flex flex-col items-center py-20 wall-bg"
  >
    <p
      class="w-full md:w-2/3 bg-dark-blue bg-opacity-50 border-y-8 text-3xl py-6 px-6 text-balance text-off-white"
    >
      Support & facilitate clear, safe and inclusive environments and
      communications for Film, TV and Live Theatre. We hold a safe space for
      entertainment professionals to maintain mental wellness within the
      workplace.
    </p>
  </div>
</template>

<script>
import instagram from "@/assets/images/icons/instagram.svg";

export default {
  data() {
    return {
      socials: [
        {
          image: instagram,
          link: "https://www.instagram.com/set-mamascentre/",
        },
      ],
    };
  },
};
</script>
