<template>
  <div id="hero"
       class="background-section background-small lg:h-section pt-navbar bg-off-white lg:hidden flex flex-col justify-center items-center"></div>
  <div id="hero"
       class="background-section background-large lg:h-section pt-navbar bg-off-white hidden lg:flex flex-col justify-center items-center"></div>
  <div
      class="bg-off-white py-[100px] md:py-navbar flex flex-col items-center justify-center md:border-y-4 border-blue w-full relative">
    <div class="w-4/5 md:w-2/3 flex flex-col">
      <h1 class="uppercase w-fit mb-20 text-4xl lg:text-6xl">Mental Health Supports for the Entertainment Industry</h1>
      <div class="text-center md:text-left my-6">
        <h1 class="uppercase my-2 text-6xl text-blue">Mission</h1>
        <p>
          We support entertainment professionals in maintaining mental wellness within the workspaces of Film, TV and Live
          Theatre.
        </p>
      </div>
      <div class="text-center md:text-left my-6">
        <h1 class="uppercase my-2 text-6xl text-blue">Vision</h1>
        <p>
          We are pioneering and prioritizing mental health support in entertainment as a must-have practice versus a nice-to-have
          option.
        </p>
        <p>
          Launched in 2023, Set Mamas is the first of its kind to bring together a collective of certified mental health
          practitioners and technology-driven supports - all dedicated to developing the highest standards and protocols around
          trauma-informed, DEI&B-informed mental wellness support for the entertainment industry.
        </p>
        <p>
          Set Mamas is a listening ear and peaceful escape for cast & crew, and makes a world of difference in:
          <ul class="my-8">
            <li>Focus & Productivity</li>
            <li>Creativity & Inspiration</li>
            <li>Morale & Collaboration</li>
          </ul>
        </p>
      </div>
    </div>
    <div class="w-full mt-40 mb-24 flex flex-col items-center justify-center relative">
      <img src="../assets/images/mindfullness.png" alt="logo" class="h-40 absolute -bottom-2">
      <hr class="w-1/3 h-1 bg-dark-blue"/>
    </div>
    <ContactSection/>
    <img src="../assets/images/sitting-two.png" alt="logo" class="h-40 absolute -bottom-20 left-0">
  </div>
</template>

<script>
import ContactSection from '@/sections/ContactSection.vue';


export default {
  name: 'HomeView',
  components: {
    ContactSection,
  },
};
</script>


<style>
.home-img {
  @apply w-full lg:w-1/3 h-auto rounded-full border-8 border-mid-blue
}

.background-section {
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
}

.background-small {
  background-size: contain; /* Cover the entire section */
  background-image: url('@/assets/images/logo/logo-small.png');
}

.background-large {
  background-size: cover; /* Cover the entire section */
  background-image: url('@/assets/images/video-camera-title-blue.png');
}

p, ul {
  @apply text-3xl xl:text-4xl text-dark-blue;
}


</style>