<template>
  <div id="contact" class="text-center w-full my-10">
    <h2 class="my-20">
      For Inquiries please contact
      <a href="mailto: info@setmamas.com?subject=Inquiry"
        ><strong class="text-blue">info@setmamas.com</strong></a
      >
    </h2>
  </div>
</template>

<style scoped></style>
<script>
export default {
  name: "ContactSection",
};
</script>
