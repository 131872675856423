<template>
  <nav id="navbar"
       class="fixed top-0 left-0 w-full h-navbar-mobile md:h-navbar w-full z-10 bg-off-white text-blue border-bronze-center border-b-4">
    <div
        class="flex items-center justify-between h-full p-4">
      <div class="items-center justify-center">
        <router-link to="/" @click="closeMenu" class="flex flex-row items-center justify-center">
          <div class="m-2 hidden md:block"><img src="../assets/images/logo/logo.png" alt="logo" class="h-16"></div>
          <h3 class="text-6xl">SET MAMAS</h3 >
         </router-link>
      </div>
      <div>
        <button @click="toggleMenu" class="lg:hidden block">
          <svg v-if="!menuOpen" class="w-10 h-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M4 6h16M4 12h16m-16 6h16"></path>
          </svg>
          <img v-else class="w-[46px] h-auto" src="../assets/images/icons/close.svg" alt="logo">
        </button>
        <div class="lg:block hidden">
          <router-link
              v-for="link in routerLinks"
              :key="link.label"
              :to="link.path"
              class="mx-4 hover:text-dark-blue uppercase lg:text-3xl xl:text-4xl hover:cursor-pointer">
            {{ link.label }}
          </router-link>
        </div>
      </div>
    </div>
    <transition name="menu-slide">
      <div v-if="menuOpen" class="flex flex-col text-off-white w-full overflow-hidden bg-mid-blue border-dark-blue border-y-4 ">
        <div v-for="link in routerLinks" :key="link" class="px-4">
          <router-link
              :key="link.label"
              :to="link.path"
              @click="closeMenu"
              class="block hover:text-blue uppercase text-5xl hover:cursor-pointer my-6">
            {{ link.label }}
          </router-link>
        </div>
      </div>
    </transition>
  </nav>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      navLinks: ['about', 'story', 'team', 'contact'],
      routerLinks: [
        { label: 'home', path: '/' },
        { label: 'about', path: '/about' },
        { label: 'contact', path: '/contact' },
      ],
      menuOpen: false, // Control the mobile menu toggle
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
      if (this.menuOpen) {
        document.body.classList.add('overflow-hidden');
      } else {
        document.body.classList.remove('overflow-hidden');
      }
    },
    closeMenu() {
      this.menuOpen = false; // Close the menu
      document.body.classList.remove('overflow-hidden'); // Enable scrolling
    },
  },
};
</script>

<style scoped>
.menu-slide-enter-active, .menu-slide-leave-active {
  transition: max-height 0.5s ease; /* Adjust the transition duration as needed */
}

.menu-slide-enter-from, .menu-slide-leave-to {
  max-height: 0; /* Initially, the dropdown is hidden */
}

.menu-slide-enter-to {
  max-height: calc(100vh - 4rem); /* Adjust the height as needed (subtracting any additional margins or paddings if necessary) */
  overflow-y: auto; /* Enable vertical scrolling if the content exceeds the viewport height */
}
</style>